import HotVideoModal from '~/components/Molecules/VideoModal/VideoModal.vue'

export default {
  components: {
    HotVideoModal,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    youtubeId: {
      type: String,
      required: true,
    },

    title: {
      type: String,
    },

    titleTag: {
      type: String,
      default: 'h3',
    },

    outsideTitle: {
      type: Boolean,
      default: false,
    },

    cover: {
      type: Object,
    },

    aspectRatio: {
      type: String,
      default: '16:9',
      validator: prop => ['1:1', '2.2:1', '2:3', '3:2', '9:16', '16:9'].includes(prop),
    },

    autoplay: {
      type: Boolean,
      default: false,
    },

    modal: {
      type: Boolean,
      default: false,
    },

    gtm: {
      type: Object,
    },
  },

  data() {
    return {
      isLoaded: false,
    }
  },

  computed: {
    src() {
      let videoUrl = this.$attrs['data-video-url']
      let autoplay = this.autoplay ? '1' : '0'
      if (videoUrl) {
        this.$attrs['visible'] = false

        return videoUrl
      } else {
        let query = `?rel=0&showinfo=0&autoplay=${autoplay}&hd=1`

        return 'https://www.youtube.com/embed/' + this.youtubeId + query
      }
    },
  },

  methods: {
    play(e) {
      if (this.gtm) {
        this.$gtm.push({
          event: 'noninteraction',
          event_category: 'CorporateWebsite:Home',
          event_action: this.gtm.show.action,
          event_label: this.gtm.show.label,
        })
      }

      if (this.modal) this.openModal(e)
      else this.isLoaded = !this.isLoaded
    },

    openModal(e) {
      const modalOptions = {
        component: HotVideoModal,
        data: {
          youtubeId: this.youtubeId,
          aspectRatio: this.aspectRatio,
        },
      }

      this.$modal.open(modalOptions)
    },
  },
}
