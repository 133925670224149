import modal from '~/mixins/modal.js'
import debounce from 'lodash.debounce'

export default {
  mixins: [modal],

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resize)
      window.dispatchEvent(new Event('resize'))
    })
  },

  destroyed() {
    window.removeEventListener('resize', this.resize)
  },

  computed: {
    aspectRatio() {
      return this.data.aspectRatio
    },

    src() {
      let query = '?rel=0&showinfo=0&autoplay=1&hd=1'

      return 'https://www.youtube.com/embed/' + this.data.youtubeId + query
    },
  },

  methods: {
    close(e) {
      this.$modal.close(this.id)
    },

    resize(e) {
      this.resizeDebounce(e, this)
    },

    resizeDebounce: debounce((e, self) => {
      const videoModal = document.querySelector('.video-modal')
      const aspectRatio = 16 / 9
      const closeButtonOffset = 80

      if (videoModal.offsetWidth / aspectRatio > videoModal.offsetHeight) {
        videoModal.style.width = `${videoModal.offsetHeight * aspectRatio - closeButtonOffset}px`
      } else {
        videoModal.style.width = 'initial'
      }
    }, 100),
  },
}
